export const PhoneNumberConstants = {
    "default": "+18775360756",
    "ACA_V1_FB_JAMES": "+18775360756",
    "ACA_US_ESS1_META_FB_ART": "+18553940298",
    "ACA_US_ESSM_META_FB_GEOFF": "+18556730704",
    "default_SPH": "+18335063463",
    "ACA_US_ESP_FB_Art":"+18884140368",
    "ACA_US_ESP_FB_Geoff":"+18884141283",
    "ACA_US_ESP_FB_ART":"+18884140368",
    "ACA_US_ESP_FB_GEOFF":"+18884141283",
    "DEV_TEST_ACA_ESP_ART":"+18884140368",
    "DEV_TEST_ACA_ESP_GEOFF":"+18884141283",
    "PRE_TEST_ACA_ESP_ART":"+18884140368",
    "PRE_TEST_ACA_ESP_GEOFF":"+18884141283",
    "ACA_ENG_FB_CB_VAN":"+18556151793",
    "ACA_ESP_FB_CB_VAN":"+18556151799",
    "ACA_ENG_FB_META_ANTONIO":"+18444551366",
    "ACA_ESP_GEO_META_ART":"+18884140368",
    "ACA_SEGURO_PL_ESP_ANTONIO":"+18665977183",
    "ACA_SEGURO_PL_ESP_ART":"+18884140368",
    "ACA_SEGURO_YT_ESP_BRM":"+18884140368",
    "ACA_SEGURO_PL_ESP_YT_RAUL":"+18333162345",
    "ACA_ESP_SUBHEALTHCARE_YT_ART":"+18884140368",
    "ACA2_ESP_SEGURO_YT_RAUL":"+18333162345",
    "ACA_SEGURO_PL_ESP_DISC_ADAM":"+18665977183",
    "ACA_SEGURO_PL_ESP_PMAX_ADAM":"+18665977183",
    "ACA_SEGURO_VID_ESP_YT_ART":"+18884140368",
    "ACA_3_SEGURO_PL_ESP_ADSYNC":"+18666450104",
    "ACA_SEGURO_ES1_FB_SAMUEL":"+18775630671",
    "ACA_SEGURO_ES1_FB_ART":"+18884140368",
    "ACA_SEGURO_ES1_FB_JAMES":"+18775080158",
    "ACA_SEG_ESP_TAB_JMS":"+18775080158",
    "ACA_SEGURO_ES1_FB_ADAM":"+18665977183",
    "default_local_NO":"+18554531966",
    "ACA_SEGURO_ES1_FB_JAMES_V2":"+18775080158",
    "ACA_SEGURO_ES_OUTBRAIN_JMS":"+18775080158",
    "ACA_SEG_ESP_TAB_2_JMS":"+18775080158",
    "ACA_SEGURO_ES_OUTBRAIN_2_JMS":"+18775080158",
    "ACA_SEGURO_ESP_PMAX_ART":"+18884140368",
    "ACA_SEGURO_ES_OUTBRAIN_ADV_JMS":"+18775080158",
    "ACA_SEG_ESP_TAB_ADV_JMS":"+18775080158",
    "ACA_ES_GEO_FB_ADV_JMS":"+18775080158",
    "ACA_SEGURO_PL_ESP_GOOGLE_SAMUEL":"+18775630671",
    "ACA_SEGURO_PL_ESP_ADV_ADAM":"+18665977183",
    "ACA_SEGURO_PL_ESP_PMAX_SAMUEL": "+18775630671",
    "ACA_SEGURO_PL_ESP_DGEN_SAMUEL" : "+18775630671",
    "ACA_SEGURO_PL_ESP_YT_SAMUEL" : "+18775630671",
    "ACA_2_SEGURO_PL_ESP_YOUTUBE_JMS" : "+18775080158",
    "ACA_2_SEGURO_PL_ESP_SEARCH_JMS" : "+18775080158",
    "ACA_2_SEGURO_PL_ESP_DGEN_JMS" : "+18775080158",
    "ACA_2_SEGURO_PL_ESP_DISCOVERY_JMS" : "+18775080158",
    "ACA_2_SEGURO_PL_ESP_PMAX_JMS" : "+18775080158",
    "ACA_SEGURO_PL_ESP_SEARCH_SAMUEL" : "+18775630671",
    "ACA_SEGURO_PL_ESP_GOOGLE_SD" : "+18554676050",
    "ACA_GPT_SEGURO_DISPLAY_JMS" : "+18775080158",
    "ACA_GPT_SEGURO_DGEN_JMS" : "+18775080158",
    "ACA_GPT_SEGURO_PMAX_JMS" : "+18775080158",
    "ACA_GPT_SEGURO_SEARCH_JMS" : "+18775080158",
    "ACA_GPT_SEGURO_YT_JMS" : "+18775080158",
    "ACA_3_SEGURO_PL_ESP_ART" : "+18884140368",
    "ACA_SEGURO_PL_ESP_GOOGLE_SAN" : "+18554676050",
    "ACA_SEGURO_PL_ESP_TAB_JMS" : "+18775080158",
    "ACA_SEGURO_GOOGLE_H_ART":"+18884140368",
    "ACA_ESP_META_ACC2_JAMES":"+18775080158",
    "ACA_HI_SEGURO_PL_ESP_YT_VAN":"+18444993894",
    "ACA_HI_SEGURO_PL_ESP_PMAX_VAN":"+18444993894",
    "ACA_HI_SEGURO_PL_ESP_DGEN_VAN":"+18444993894",
    "ACA_SEGURO_PL_ESP_YT_ADAM":"+18665977183"
};

export const PhoneNumberConstants_VSL_Splits = {
    "default_SPH": "+18773540548",
    "default_local_NO": "+18773540548",
    "ACA_SEGURO_PL_ESP_DISC_ADAM": "+18888380786",
    "ACA_SEGURO_PL_ESP_PMAX_ADAM": "+18888380786",
    "ACA_SEGURO_ES1_FB_ADAM": "+18888380786",
    "ACA_SEG_ESP_TAB_JMS": "+18888380820",
    "ACA_SEGURO_ES_OUTBRAIN_JMS": "+18888380820",
    "ACA_3_SEGURO_PL_ESP_ADSYNC": "+18664921178",
    "ACA_SEGURO_ES1_FB_SAMUEL": "+18888380750",
    "ACA_SEGURO_ES1_FB_ART": "+18336322846",
    "ACA_SEGURO_VID_ESP_YT_ART": "+18336322846",
    "ACA_SEGURO_YT_ESP_BRM": "+18336322846",
    "ACA_SEGURO_PL_ESP_ART": "+18336322846",
};

export const MedicarePhoneNumberConstants = {
    "default": "+18339601544",
    "default_local_NO": "+18339601544",
    "ACA_SEGURO_ESP_PMAX_ART":"+18446550358",
    "ACA_SEGURO_ES_OUTBRAIN_2_JMS" : "+18333092931",
    "ACA_SEG_ESP_TAB_2_JMS" : "+18333092931",
    "ACA_SEGURO_ES_OUTBRAIN_JMS" : "+18333092931",
    "ACA_SEGURO_ES1_FB_JAMES_V2" : "+18333092931",
    "ACA_SEG_ESP_TAB_JMS" : "+18333092931",
    "ACA_SEGURO_ES1_FB_JAMES" : "+18333092931",
    "ACA_SEGURO_ES1_FB_ADAM" : "+18776992280",
    "ACA_SEGURO_PL_ESP_PMAX_ADAM" : "+18776992280",
    "ACA_SEGURO_PL_ESP_DISC_ADAM" : "+18776992280",
    "ACA_SEGURO_PL_ESP_ANTONIO" : "+18776992280",
    "ACA_SEGURO_ES1_FB_SAMUEL" : "+18775952043",
    "ACA_SEGURO_ES1_FB_ART" : "+18446550358",
    "ACA_SEGURO_YT_ESP_BRM" : "+18446550358",
    "ACA_SEGURO_PL_ESP_ART" : "+18446550358",
    "ACA_3_SEGURO_PL_ESP_ADSYNC" : "+18776920442",
    "ACA_ES_GEO_FB_ADV_JMS":"+18333092931"


};

export const GreencardPhoneNumberConstants = {
    "default": "+18885380531",
    "default_local_NO": "+18885380531",
    "ACA_SEGURO_ESP_PMAX_ART":"+18446530324",
    "ACA_SEGURO_ES_OUTBRAIN_2_JMS" : "+18557141034",
    "ACA_SEG_ESP_TAB_2_JMS" : "+18557141034",
    "ACA_SEGURO_ES_OUTBRAIN_JMS" : "+18557141034",
    "ACA_SEGURO_ES1_FB_JAMES_V2" : "+18557141034",
    "ACA_SEG_ESP_TAB_JMS" : "+18557141034",
    "ACA_SEGURO_ES1_FB_JAMES" : "+18557141034",
    "ACA_SEGURO_ES1_FB_ADAM" : "+18446471346",
    "ACA_SEGURO_PL_ESP_PMAX_ADAM" : "+18446471346",
    "ACA_SEGURO_PL_ESP_DISC_ADAM" : "+18446471346",
    "ACA_SEGURO_PL_ESP_ANTONIO" : "+18446471346",
    "ACA_SEGURO_ES1_FB_SAMUEL" : "+18554973175",
    "ACA_SEGURO_ES1_FB_ART" : "+18446530324",
    "ACA_SEGURO_YT_ESP_BRM" : "+18446530324",
    "ACA_SEGURO_PL_ESP_ART" : "+18446530324",
    "ACA_3_SEGURO_PL_ESP_ADSYNC" : "+18777681073",
    "ACA_ES_GEO_FB_ADV_JMS":"+18557141034",
    "ACA_2_SEGURO_PL_ESP_YOUTUBE_JMS" : "+18557141034",
    "ACA_2_SEGURO_PL_ESP_SEARCH_JMS" : "+18557141034",
    "ACA_2_SEGURO_PL_ESP_DGEN_JMS" : "+18557141034",
    "ACA_2_SEGURO_PL_ESP_DISCOVERY_JMS" : "+18557141034",
    "ACA_2_SEGURO_PL_ESP_PMAX_JMS" : "+18557141034",
    "ACA_SEGURO_PL_ESP_SEARCH_SAMUEL" : "+18554973175",
    "ACA_SEGURO_PL_ESP_GOOGLE_SD" : "+18665754050",
    "ACA_GPT_SEGURO_DISPLAY_JMS" : "+18557141034",
    "ACA_GPT_SEGURO_DGEN_JMS" : "+18557141034",
    "ACA_GPT_SEGURO_PMAX_JMS" : "+18557141034",
    "ACA_GPT_SEGURO_SEARCH_JMS" : "+18557141034",
    "ACA_GPT_SEGURO_YT_JMS" : "+18557141034",
    "ACA_3_SEGURO_PL_ESP_ART" : "+18446530324",
    "ACA_SEGURO_PL_ESP_GOOGLE_SAN" : "+18665754050",
    "ACA_SEGURO_PL_ESP_TAB_JMS" : "+18557141034",
    "ACA_SEGURO_GOOGLE_H_ART": "+18446530324",
    "ACA_ESP_META_ACC2_JAMES": "+18557141034",
    "ACA_HI_SEGURO_PL_ESP_YT_VAN":"+18556592693",
    "ACA_HI_SEGURO_PL_ESP_PMAX_VAN":"+18556592693",
    "ACA_HI_SEGURO_PL_ESP_DGEN_VAN":"+18556592693",
    "ACA_SEGURO_PL_ESP_YT_ADAM":"+18446471346"
};
