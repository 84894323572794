// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from 'react';

const NotFound = lazy(() => import('./Components/Pages/NotFound'));
const ACA_SPH_V3 = lazy(() => import('./Components/Pages/ACA_SPH_V3/ACA_SPH_V3'));
const SPR_V1 = lazy(() => import('./Components/Pages/SPR_V1/SPR_V1'));
const SPR_V3 = lazy(() => import('./Components/Pages/SPR_V3/SPR_V3'));
const SPR_V2 = lazy(() => import('./Components/Pages/SPR_V2/SPR_V2'));
const SPR_V2_Thankyou = lazy(() => import('./Components/Pages/SPR_V2/SPR_V2_Thankyou'));
const SPR_V4 = lazy(() => import('./Components/Pages/SPR_V4/SPR_V4'));
const AdtopiaLoadValue = lazy(() => import('./Components/Pages/AdtopiaLoadValue'));
const SPR_V5 = lazy(() => import('./Components/Pages/SPR_V5/SPR_V5'));
const SPR_V5MVT = lazy(() => import('./Components/Pages/SPR_V5MVT/SPR_V5MVT'));
const SPR_V5_1 = lazy(() => import('./Components/Pages/SPR_V5_1/SPR_V5_1'));
const SPR_V6B_MS = lazy(() => import('./Components/Pages/SPR_V6B_MS/SPR_V6B_MS'));
const SPR_V7 = lazy(() => import('./Components/Pages/SPR_V7/SPR_V7'));
const SPR_V8_1 = lazy(() => import('./Components/Pages/SPR_V8_1/SPR_V8_1'));
const SPR_V8_4 = lazy(() => import('./Components/Pages/SPR_V8_4/SPR_V8_4'));
const SPR_V8_4B = lazy(() => import('./Components/Pages/SPR_V8_4B/SPR_V8_4B'));
const SPR_V8_2 = lazy(() => import('./Components/Pages/SPR_V8_2/SPR_V8_2'));
const SPR_V8_3 = lazy(() => import('./Components/Pages/SPR_V8_3/SPR_V8_3'));
const SPR_V8_5 = lazy(() => import('./Components/Pages/SPR_V8_5/SPR_V8_5'));
const SPR_V8_6 = lazy(() => import('./Components/Pages/SPR_V8_6/SPR_V8_6'));
const SPR_V8_7 = lazy(() => import('./Components/Pages/SPR_V8_7/SPR_V8_7'));
const SPR_V8_8 = lazy(() => import('./Components/Pages/SPR_V8_8/SPR_V8_8'));
const SPR_V8_9 = lazy(() => import('./Components/Pages/SPR_V8_9/SPR_V8_9'));
const SPR_V5B = lazy(() => import('./Components/Pages/SPR_V5B/SPR_V5B'));
const SPR_V8_9B = lazy(() => import('./Components/Pages/SPR_V8_9B/SPR_V8_9B'));
const SPR_V8_4C = lazy(() => import('./Components/Pages/SPR_V8_4C/SPR_V8_4C'));
const SPR_FF_V2_1 = lazy(() => import('./Components/Pages/SPR_FF_V2_1/SPR_FF_V2_1'));
const SPR_V8_42 = lazy(() => import('./Components/Pages/SPR_V8_42/SPR_V8_42'));
const SPR_VD_V9 = lazy(() => import('./Components/Pages/SPR_VD_V9/SPR_VD_V9'));
const SPR_WA_V11 = lazy(() => import('./Components/Pages/SPR_WA_V11/SPR_WA_V11'));
const SPR_V10 = lazy(() => import('./Components/Pages/SPR_V10/SPR_V10'));
const SPR_FB_V_8_4_1 = lazy(() => import('./Components/Pages/SPR_FB_V_8_4_1/SPR_FB_V_8_4_1'));
const SPR_VD_V9_1 = lazy(() => import('./Components/Pages/SPR_VD_V9_1/SPR_VD_V9_1'));
const SPR_VD_V9B = lazy(() => import('./Components/Pages/SPR_VD_V9B/SPR_VD_V9B'));
const SPR_VD_FBV9B_1 = lazy(() => import('./Components/Pages/SPR_VD_FBV9B_1/SPR_VD_FBV9B_1'));
const HomePage = lazy(() => import('./Components/Pages/HomePage'));
const SPR_V8_S4_1 = lazy(() => import('./Components/Pages/SPR_V8_S41/SPR_V8_S41'))
const SPR_SPH_V10 = lazy(() => import('./Components/Pages/SPR_SPH_V10/SPR_SPH_V10'))
const SPR_V9 = lazy(() => import('./Components/Pages/SPR_V9/SPR_V9'))

const AppSplitList = (props) => {

    const SplitListMap = {
        'NotFound': NotFound,
        'ACA_SPH_V3': ACA_SPH_V3,
        'SPR_V1': HomePage,
        'SPR_V3': SPR_V3,
        'SPR_V2': SPR_V2,
        'SPR_V2_Thankyou': SPR_V2_Thankyou,
        'SPR_V4': SPR_V4,
        'AdtopiaLoadValue': AdtopiaLoadValue,
        'SPR_V5': SPR_V5,
        'SPR_V5MVT': SPR_V5MVT,
        'SPR_V5_1': SPR_V5_1,
        'SPR_V6B' : HomePage,
        'SPR_V6B_MS': SPR_V6B_MS,
        'SPR_V7': SPR_V7,
        'SPR_V8_1': SPR_V8_1,
        'SPR_V8_4': SPR_V8_4,
        'SPR_V8_4B': SPR_V8_4B,
        'SPR_V8_2': SPR_V8_2,
        'SPR_V8_3': SPR_V8_3,
        'SPR_V8_5': SPR_V8_5,
        'SPR_V8_6': SPR_V8_6,
        'SPR_V8_7': SPR_V8_7,
        'SPR_V8_8': SPR_V8_8,
        'SPR_V8_9': SPR_V8_9,
        'SPR_V5B': SPR_V5B,
        'SPR_V8_9B': SPR_V8_9B,
        'SPR_V8_4C': SPR_V8_4C,
        'SPR_FF_V2_1': SPR_FF_V2_1,
        'SPR_V8_42': SPR_V8_42  ,
        'SPR_VD_V9': SPR_VD_V9,
        'SPR_WA_V11': SPR_WA_V11,
        'SPR_V10': SPR_V10,
        'SPR_FB_V_8_4_1': SPR_FB_V_8_4_1,
        'SPR_VD_V9_1': SPR_VD_V9_1,
        'SPR_VD_V9B' : SPR_VD_V9B,
        'SPR_VD_FBV9B_1': SPR_VD_FBV9B_1,
        'SPR_V8_S4_1' : SPR_V8_S4_1,
        'SPR_SPH_V10':SPR_SPH_V10,
        'SPR_V9' : SPR_V9

    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;